export const Timeline = () => {
    return (
        <div className="max-w-3xl mx-auto -my-4 md:-my-6" data-aos-id-timeline>
            {/* 1st item */}
            <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-anchor="[data-aos-id-timeline]">
                <div className="pl-2">
                    <div className="font-architects-daughter text-xl text-purple-600 mb-2">Vaag Antwerpen</div>
                    <div className="flex items-center mb-3">
                        <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">02/06</div>
                        <div className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                        <div className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                        <h4 className="h4 text-white py-0">Title of the Event, Antwerp</h4>
                    </div>
                    <p className="text-lg text-gray-400">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</p>
                </div>
            </div>
            <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-anchor="[data-aos-id-timeline]">
                <div className="pl-2">
                    <div className="font-architects-daughter text-xl text-purple-600 mb-2">Vaag Antwerpen</div>
                    <div className="flex items-center mb-3">
                        <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">31/06</div>
                        <div className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                        <div className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                        <h4 className="h4 text-white py-0">Title of the Event, Antwerp</h4>
                    </div>
                    <p className="text-lg text-gray-400">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</p>
                </div>
            </div>
            <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-anchor="[data-aos-id-timeline]">
                <div className="pl-2">
                    <div className="font-architects-daughter text-xl text-purple-600 mb-2">Fuse Brussel</div>
                    <div className="flex items-center mb-3">
                        <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">03/08</div>
                        <div className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                        <div className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                        <h4 className="h4 text-white py-0">Title of the Event, Brussel</h4>
                    </div>
                    <p className="text-lg text-gray-400">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</p>
                </div>
            </div>
        </div>
    );
}