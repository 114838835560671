// @ts-nocheck
import { useState, useRef, useEffect } from 'react';
import Transition from 'components/@utils/Transition';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { ImArrowRight2 } from 'react-icons/im';
import { useRouter } from 'next/router';
import Dropdown from './@utils/Dropdown';

const Header: React.FC = () => {
  const { t } = useTranslation("common")
  const { locale } = useRouter();

  const languages = ['EN', 'NL', 'FR']

  const menuItems = [
    { label: t("general.header.movies"), to: "#movies" },
    { label: t("general.header.about"), to: "#about" },
    { label: t("general.header.songs"), to: "#tracks" },
    { label: t("general.header.contact"), to: "#contact" },
    { label: t("general.header.events"), to: "#events" },
  ]

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const trigger = useRef<HTMLElement>(null);
  const mobileNav = useRef<HTMLElement>(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 300 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);


  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <header className={`${top ? "absolute" : "fixed"} w-full z-30 dark ${!top && "bg-slate-900"} transition ease-in-out`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link href="/" passHref aria-label="Cruip">
              <a className="block text-purple-600 transition duration-150 ease-in-out flex items-center gap-4" aria-label="Cruip">
                <Image src="/assets/logo/manezz-white.svg" width={50} height={50} alt="logo" />
              </a>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">

            {/* Desktop menu links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              {menuItems.map(item => (
                <li key={item.label} className="font-medium text-slate-800 hover:text-purple-600 dark:text-slate-400 dark:hover:text-purple-600 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  <Link passHref href={item.to}>{item.label}</Link>
                </li>
              ))}
              {/* 1st level: hover */}
              <Dropdown title={locale!.toLocaleUpperCase()}>
                {/* 2nd level: hover */}
                {languages.map(language => (
                  <li key={language} className={`cursor-pointer z-10 font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight ${locale?.toUpperCase() === language.toUpperCase() && 'font-bold'}`}>
                    <Link passHref href="/" locale={language.toLowerCase()}>
                      {language}
                    </Link>
                  </li>
                ))}
              </Dropdown>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="flex md:hidden">

            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-slate-900 dark:text-slate-100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
                appear={undefined}
              >
                <ul className="px-5 py-2">
                  {menuItems.map(item => (
                    <Link key={item.label} passHref href={item.to}>
                      <li onClick={() => setMobileNavOpen(false)} className="flex font-bold justify-between items-center text-slate-800 hover:text-purple-600 py-2 border-b-2">
                        {item.label} <ImArrowRight2 size={12} />
                      </li>
                    </Link>
                  ))}
                  <li className="font-bold justify-between items-center text-slate-800 hover:text-purple-600 py-2 border-b-2">
                    <span className="flex py-2">{t("general.header.language")}</span>
                    <ul className="pl-4">
                      {languages.map(language => (
                        <Link key={language} passHref href="/" locale={language.toLowerCase()}>
                          <li onClick={() => setMobileNavOpen(false)} className={`text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2  ${locale?.toUpperCase() === language.toUpperCase() && 'font-bold'}`}>
                            {language}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </li>
                </ul>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
